import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  homeGroupKeys,
  useUpdateHomeGroupMembersCache,
} from "src/data/homeGroups/queries/homeGroupQueryCache"
import {
  HomeGroupRole,
  IFetchHomeGroupMembers,
  IHomeGroupMember,
} from "src/data/homeGroups/types/homeGroupTypes"
import { postOrganizationInvite } from "src/data/organizations/queries/inviteQueries"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import {
  InvitationType,
  IOrganizationInvitationPost,
  TOrganizationInvitation,
} from "src/data/organizations/types/organizationTypes"
import { IPagingFilter } from "src/data/pagination/types/paginationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

// ts-prune-ignore-next
export function useFetchHomeGroupMember({
  orgId,
  homeGroupId,
  memberId,
  options,
}: {
  orgId: string
  homeGroupId: string
  memberId: string
  options?: UseQueryOptions<
    IHomeGroupMember,
    AxiosError,
    IHomeGroupMember,
    ReturnType<typeof homeGroupKeys.memberDetail>
  >
}) {
  async function fetchHomeGroupMember(): Promise<IHomeGroupMember> {
    const response = await minutApiHttpClient.get<IHomeGroupMember>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/members/${memberId}`
    )
    const member: IHomeGroupMember = response.data

    return member
  }

  return useQuery(
    homeGroupKeys.memberDetail(orgId, homeGroupId, memberId),
    fetchHomeGroupMember,
    {
      ...options,
      enabled:
        options?.enabled === undefined
          ? !!memberId
          : options.enabled && !!memberId,
    }
  )
}

export function useFetchHomeGroupMembers({
  orgId,
  homeGroupId,
  filter,
  options,
}: {
  orgId: string
  homeGroupId: string
  filter?: IPagingFilter
  options?: UseQueryOptions<
    IFetchHomeGroupMembers,
    AxiosError,
    IFetchHomeGroupMembers,
    ReturnType<typeof homeGroupKeys.membersList>
  >
}) {
  async function fetchHomeGroupMembers(): Promise<IFetchHomeGroupMembers> {
    const response = await minutApiHttpClient.get<IFetchHomeGroupMembers>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/members/`,
      { params: filter }
    )
    return response.data
  }

  return useQuery(
    homeGroupKeys.membersList(orgId, homeGroupId, filter),
    fetchHomeGroupMembers,
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

interface IPatchHomeGroupMember {
  orgId: string
  homeGroupId: string
  homeGroupMemberId: string
  body: { role: HomeGroupRole }
}
export function usePatchHomeGroupMember() {
  const { updateCachedHomeGroupMember } = useUpdateHomeGroupMembersCache()

  async function patchHomeGroupMember({
    orgId,
    homeGroupId,
    homeGroupMemberId,
    body,
  }: IPatchHomeGroupMember): Promise<IHomeGroupMember> {
    const response = await minutApiHttpClient.patch<IHomeGroupMember>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/members/${homeGroupMemberId}`,
      { ...body }
    )
    return response.data
  }

  return useMutation<IHomeGroupMember, AxiosError, IPatchHomeGroupMember>(
    patchHomeGroupMember,
    {
      onSuccess: async (member, vars) => {
        updateCachedHomeGroupMember(
          vars.orgId,
          vars.homeGroupId,
          vars.homeGroupMemberId,
          () => member
        )
      },
    }
  )
}

interface IPostHomeGroupMemberInvitation {
  orgId: string
  homeGroupId: string
  body: Pick<IOrganizationInvitationPost, "email" | "role">
}
export function usePostHomeGroupMemberInvite() {
  const cache = useQueryClient()

  async function postHomeGroupMemberInvite({
    orgId,
    homeGroupId,
    body,
  }: IPostHomeGroupMemberInvitation) {
    const response = await postOrganizationInvite({
      orgId,
      body: {
        ...body,
        invitation_target_id: homeGroupId,
        type: InvitationType.HOMEGROUP,
      },
    })
    return response.data
  }

  return useMutation<
    TOrganizationInvitation,
    AxiosError,
    IPostHomeGroupMemberInvitation
  >(postHomeGroupMemberInvite, {
    onSuccess: async (invite, vars) => {
      await cache.invalidateQueries(orgsKeys.invitations(vars.orgId))
    },
  })
}

interface IDeleteHomeGroupMemberMutation {
  orgId: string
  homeGroupId: string
  homeGroupMemberId: string
}
export function useDeleteHomeGroupMember() {
  const { removeCachedHomeGroupMember } = useUpdateHomeGroupMembersCache()
  async function deleteHomeGroupMember({
    orgId,
    homeGroupId,
    homeGroupMemberId,
  }: IDeleteHomeGroupMemberMutation) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/members/${homeGroupMemberId}`
    )
    return response.data
  }

  return useMutation<unknown, AxiosError, IDeleteHomeGroupMemberMutation>(
    deleteHomeGroupMember,
    {
      onSuccess: async (_, { orgId, homeGroupId, homeGroupMemberId }) => {
        removeCachedHomeGroupMember(orgId, homeGroupId, homeGroupMemberId)
      },
    }
  )
}

interface IPostHomeGroupMember {
  orgId: string
  homeGroupId: string
  homeGroupMemberId: string
  role?: HomeGroupRole
}
interface IPostHomeGroupMemberResponse {
  member_id: string
  user_id: string
  joined_at: string // date-time
  role: HomeGroupRole
  name: string
  email: string
}

export function usePostHomeGroupMember() {
  const { addCachedHomeGroupMember } = useUpdateHomeGroupMembersCache()

  async function postHomeGroupMember({
    orgId,
    homeGroupId,
    homeGroupMemberId,
  }: IPostHomeGroupMember): Promise<IPostHomeGroupMemberResponse> {
    const response =
      await minutApiHttpClient.post<IPostHomeGroupMemberResponse>(
        `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/members`,
        {
          member_id: homeGroupMemberId,
          role: "member",
        }
      )
    return response.data
  }

  return useMutation<
    IPostHomeGroupMemberResponse,
    AxiosError,
    IPostHomeGroupMember
  >(postHomeGroupMember, {
    onSuccess: async (m, { orgId, homeGroupId, homeGroupMemberId }) => {
      addCachedHomeGroupMember(orgId, homeGroupId, () => m)
    },
  })
}
